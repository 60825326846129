



































































































































































































































































































































































































































































import Vue from "vue";
import MakePayment from "./MakePayment.vue";
import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { apiBaseUrl } from "@/enviorment";
import { AxiosError } from "axios";
import PdfView from "@/components/PDF/PdfView.vue";
import DocumentViewer from "../PdfSign/DocumentViewer.vue";
import { mapGetters, mapState } from "vuex";
export default Vue.extend({
  components: { MakePayment, PdfView },
  name: "patient-payment",
  props: {
    procedure: Object,
  },
  data() {
    return {
      contador: 0,
      transfer: {
        refunddialog: false,
        transferdialog: false,
        transferitems: [],
        transferamount: 0,
        transferToProcedure: "",
      },
      fail: false,
      total: 0,
      confircode: "",
      selected: [],
      loading: false,
      loadingpdf: false,
      showBreakdown: false,
      showInvoice: false,
      item: null,
      dialogconfirm: false,
      errorcode: false,
      confirm: null,
    };
  },
  watch: {
    contador(val) {
      if (val == (this as any).transfer.transferitems.length) {
        this.$emit("update-patient");
        (this as any).canceltransfer();
      }
    },
    confircode(val) {
      if (val == null || val == "" || val == undefined) {
        (this as any).errorcode = false;
      }
    },
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patientDetail"]),
    ...mapGetters(["isSuper", "isAdmin", "getviewpayments"]),

    procedures() {
      if (this.patientDetail.proceduresEntity.length == 0) {
        return [];
      }
      const actives = this.patientDetail.proceduresEntity.filter(
        (p: any) => p.activeStatus == "ACTIVE"
      );
      const proced = actives.map((p: any) => {
        const name =
          p.treatmentType != null ? p.treatmentType.name : p.surgeryType.name;
        return { name: name, id: p.id };
      });
      return proced;
    },

    invoice() {
      if (
        this.procedure.stripeInvoice == null ||
        this.procedure.stripeInvoice == undefined
      ) {
        return null;
      }
      const invo = {
        title: this.procedure.stripeInvoice.stripeObj.description,
        url: this.procedure.stripeInvoice.pdfUrl,
      };
      return invo;
    },

    headers() {
      if (!(this as any).fail) {
        return [
          { text: "Payment type", value: "type" },
          { text: "Amount", value: "amount" },
          { text: "Status", value: "status" },
          { text: "Card", value: "stripeCardId" },
          { text: "Payment date", value: "paymentDate" },
          { text: "", value: "actions" },
        ];
      } else {
        return [
          { text: "Payment type", value: "type" },
          { text: "Amount", value: "amount" },
          { text: "Status", value: "status" },
          { text: "Payment date", value: "paymentDate" },
          { text: "", value: "actions" },
        ];
      }
    },

    paymentsConfirmed() {
      if (
        this.procedure.payments == undefined ||
        this.procedure.payments == null
      ) {
        return [];
      }
      return this.procedure.payments.filter(
        (proc: any) => proc.status == "CONFIRMED"
      );
    },

    paymentNoConfirm() {
      return this.procedure.payments.filter(
        (proc: any) => proc.status != "CONFIRMED"
      );
    },

    confpay() {
      const x = (this as any).confirm;

      return x;
    },

    payments(): number {
      if (
        this.procedure.payments == undefined ||
        this.procedure.payments.length == 0
      ) {
        return 0;
      }

      let paids = 0.0;
      const payms = this.procedure.payments;
      payms.forEach((paid: any) => {
        const pay = paid.status == "CONFIRMED" ? parseFloat(paid.amount) : 0.0;
        paids = paids + pay;
      });

      return paids;

      /*  .reduce(
        (total: number, item: { amount: string }) => {
          return total + parseFloat(item.amount);
        },
        0
      ); */

      /*  return result; */
    },
    deposit(): number {
      if ((this as any).payments == 0) return 0;

      return this.procedure.contract.deposit;
    },
    deb(): number {
      const payments = (this as any).payments || 0;
      return (this as any).totalCost - payments;
    },
    totalCost(): number {
      return Number(this.procedure.totalTreatment);
    },
  },
  methods: {
    toTransfer(item: any, type: string) {
      (this as any).transfer.transferdialog = true;
      if (type == "self") {
        (this as any).transfer.transferitems = [item.uuid];
        (this as any).transfer.transferamount = item.amount;
      } else {
        let amount = 0;
        const arry = (this as any).selected.map((sel: any) => {
          amount = amount + Number(sel.amount);
          return sel.uuid;
        });
        (this as any).transfer.transferitems = arry;
        (this as any).transfer.transferamount = amount;
      }
    },
    confirmTransfer() {
      (this as any).transfer.transferitems.forEach(async (element: any) => {
        (this as any).loading = true;
        getAPI
          .put("/payment/transfer-payment", {
            paymentUuid: element,
            procedureId: (this as any).transfer.transferToProcedure,
          })
          .then(() => {
            (this as any).contador++;
            (this as any).loading = false;
          })
          .catch((error: any) => {
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      });
      if (
        (this as any).contador == (this as any).transfer.transferitems.length
      ) {
        this.$emit("update-patient");
        (this as any).canceltransfer();
      }
    },
    toRefund(item: any, type: string) {
      (this as any).transfer.refunddialog = true;
      if (type == "self") {
        (this as any).transfer.transferitems = [item.uuid];
      } else {
        const arry = (this as any).selected.map((sel: any) => {
          return sel.uuid;
        });

        (this as any).transfer.transferitems = arry;
      }
    },
    confirmRefund() {
      (this as any).transfer.transferitems.forEach(async (element: any) => {
        (this as any).loading = true;
        getAPI
          .put("/payment/refound-payment/" + element)
          .then(() => {
            (this as any).contador++;
            (this as any).loading = false;
          })
          .catch((error: any) => {
            (this as any).loading = false;
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      });
      if (
        (this as any).contador == (this as any).transfer.transferitems.length
      ) {
        this.$emit("update-patient");
        (this as any).canceltransfer();
      }
    },

    canceltransfer() {
      (this as any).loading = false;
      (this as any).selected = [];
      (this as any).contador = 0;
      (this as any).transfer = {
        transferdialog: false,
        refunddialog: false,
        transferitems: [],
        transferamount: 0,
        transferToProcedure: "",
      };
    },

    async downloadPDFPayment() {
      (this as any).loadingpdf = true;
      getAPI
        .get(
          apiBaseUrl + "/sprocedures/getPrestigeTicketPdf/" + this.procedure.id
        )
        .then((res) => {
          console.log(res.data);
          (this as any).loadingpdf = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);

          (this as any).loadingpdf = false;
        });

      /*  const a = document.createElement("A") as HTMLLinkElement;
      a.href = url;
      a.setAttribute("style", "display: none");
      a.setAttribute("target", "_blank");
      (a as any).download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); */
    },

    sendInvoice() {
      (this as any).loading = true;
      getAPI
        .put(`/sprocedures/sendStripeInvoice/${this.procedure.id}`)
        .then(() => {
          notifySuccess("Invoice has been sended");
          this.$emit("update-patient");
          (this as any).loading = false;
        })
        .catch((error: any) => {
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);

          (this as any).loading = false;
        });
    },

    paymentDate(date: string) {
      return new Date(date).toISOString().slice(0, 10);
    },
    typesShow(type: string) {
      if (type == "External_Card_Reader") {
        return "Card Reader";
      }
      if (type == "Stripe") {
        return "Card";
      }
      return type;
    },
    toConfirm(item: any) {
      (this as any).dialogconfirm = true;
      (this as any).item = item;
    },
    confirmPay() {
      if ((this as any).confircode == "12345678") {
        (this as any).confirm = { confirm: true, payment: (this as any).item };

        (this as any).sendConfirm();
      } else {
        (this as any).errorcode = true;
      }
    },
    sendConfirm() {
      (this as any).item = null;
      (this as any).dialogconfirm = false;
      (this as any).confircode = "";
      (this as any).errorcode = false;
    },

    cancel() {
      (this as any).confirm = null;
      (this as any).item = null;
      (this as any).dialogconfirm = false;
      (this as any).confircode = "";
      (this as any).errorcode = false;
    },

    confirmated() {
      (this as any).cancel();
    },

    card(item: any) {
      if (item.type == "Stripe") {
        let idc = item.stripeCardId;

        if (idc.includes("{")) {
          idc = JSON.parse(idc);

          return idc.card.brand == "mastercard"
            ? "MasterCard**" + idc.card.last4
            : idc.card.brand + "****" + idc.card.last4;
        } else {
          const cardtem = this.patientDetail.stripeCards.filter(
            (c: any) => c.id == idc
          );
          if (cardtem == undefined) {
            return (this as any).typesShow(item.type);
          }

          if (cardtem.length == 0) {
            return "****";
          }
          return cardtem[0].brand + "****" + cardtem[0].last4;
        }
      } else if (item.type == "External_Card_Reader") {
        const data = item.stripeCardId.includes("{")
          ? JSON.parse(item.stripeCardId).card_present
          : "Card Reader";
        if (data == "Card Reader") {
          return data;
        } else {
          return data.brand == "mastercard"
            ? "MasterCard**" + data.last4
            : data.brand + "****" + data.last4;
        }
      }

      return (this as any).typesShow(item.type);
    },
  },
});
